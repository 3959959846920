import {AppManifestBuilder} from '@wix/app-manifest-builder';
import {WidgetPluginInterfaces} from '@wix/widget-plugins-ooi/interfaces';
import {StoresWidgetID} from '@wix/wixstores-client-core';

export const configureSlots = (appManifestBuilder: AppManifestBuilder): AppManifestBuilder =>
  appManifestBuilder.configureWidget(StoresWidgetID.CHECKOUT, (widgetBuilder) => {
    widgetBuilder.slots().set({
      'checkout-slot': {
        displayName: 'Checkout Slot',
        interfaces: [WidgetPluginInterfaces.DEFAULT],
      },
    });
  });
